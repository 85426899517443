.hero-bg {
  background-image: url("./components/assets/png/hero-bg.webp");
  background-position: center top;
  background-size: 100% 1150px;
  background-repeat: no-repeat;
}
.hero-bg2 {
  background-image: url("./components/assets/png/Referral-bg.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-bg3 {
  background-image: url("./components/assets/png/security-bg.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-bg4 {
  background-image: url("./components/assets/png/trading.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-bg5 {
  background-image: url("./components/assets/png/company-bg.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-bg-2 {
  background-size: 100% 1000px;
}

.bg-img-comminity {
  background-image: url("./components/assets/png/commity-bg.png");
  background-color: #132296;
  background-position: center;
  background-size: cover;

}

@media (max-width:768px) {
  .hero-bg {
    background-size: cover;
  }
}

.scrol-hide::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */
.scrol-hide::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
.scrol-hide::-webkit-scrollbar-thumb {
  background: #88888800;
}

/* Handle on hover */
.scrol-hide::-webkit-scrollbar-thumb:hover {
  background: #55555500;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Graphik LCG black";
  src: url("./components/assets/font/GraphikLCG-Black.ttf");
}

@font-face {
  font-family: "Graphik LCG bold";
  src: url("./components/assets/font/GraphikLCG-Bold.ttf");
}

@font-face {
  font-family: "Graphik LCG extralight";
  src: url("./components/assets/font/GraphikLCG-Extralight.ttf");
}

@font-face {
  font-family: "Graphik LCG light";
  src: url("./components/assets/font/GraphikLCG-Light.ttf");
}

@font-face {
  font-family: "Graphik LCG medium";
  src: url("./components/assets/font/GraphikLCG-Medium.ttf");
}

@font-face {
  font-family: "Graphik LCG";
  src: url("./components/assets/font/GraphikLCG-Regular.ttf");
}

@font-face {
  font-family: "Graphik LCG semibold";
  src: url("./components/assets/font/GraphikLCG-Semibold.ttf");
}

@font-face {
  font-family: "Graphik LCG thin";
  src: url("./components/assets/font/GraphikLCG-Thin.ttf");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Graphik LCG";

}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

::marker {
  font-size: 30px;
  color: black;
}

.white-dot ::marker {
  font-size: 30px;
  color: white !important;
}